<template>
  <div id="LeasedCarsPercentage">
    <h2>品牌占比率</h2>
    <echarts-com v-loading="loading" id="LeasedCarsChart" :options="options" />
  </div>
</template>

<script>
import echartsCom from "@/components/echartsCom";
import { formatRatio } from "@/utils/util";
export default {
  components: {
    echartsCom,
  },
  data() {
    return {
      businessCircleId: "",
      parkingLotId: "",
      loading: false,
      options: {},
    };
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
    businessList() {
      return this.$store.state.select.businessList;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        let res = await this.$http.post("/statistics/vehicle/preference");
        if (res.code === 0) {
          if (res.data) {
            let data = res.data.brands.map((item) => {
              return {
                value: item.num,
                percentage: formatRatio(item.percentage) + "%",
                name: item.typeName,
              };
            });

            this.$set(this, "options", {
              tooltip: {
                trigger: "item",
              },
              legend: {
                type: "scroll",
                icon: "circle",
                orient: "vertical",
                right: 0,
                top: "center",
                itemWidth: 10,
                itemHeight: 10,
                pageIconColor: "rgba(255,255,255,0.8)",
                pageIconInactiveColor: "rgba(255,255,255,0.3)",
                formatter(labelName) {
                  let item = data.find((item) => item.name === labelName);
                  return `{name|${item.name}}{dividingLine||}{percentage|${item.percentage}}`;
                },
              },
              textStyle: {
                rich: {
                  name: {
                    width: 70,
                    color: "white",
                  },
                  dividingLine: {
                    color: "#eee",
                    width: 10,
                  },
                  percentage: {
                    color: "white",
                    width: 55,
                  },
                },
              },
              series: [
                {
                  type: "pie",
                  radius: ["67%", "90%"],
                  left: 0,
                  right: "50%",
                  top: 0,
                  bottom: 0,
                  avoidLabelOverlap: false,
                  itemStyle: {
                    borderWidth: 100,
                  },
                  label: {
                    show: false,
                    position: "center",
                  },
                  emphasis: {
                    label: {
                      show: true,
                      fontWeight: "bold",
                      color: "white",
                    },
                  },
                  labelLine: {
                    show: false,
                  },
                  data,
                },
              ],
            });
          } else {
            this.$set(this, "options", {});
            this.list = [];
          }
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch {
        this.$message.error("车辆品牌分析获取失败");
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#LeasedCarsPercentage {
  height: calc(35% - 4rem);

  .el-select-dropdown {
    background-color: red;
  }

  #LeasedCarsChart {
    width: 100%;
    height: calc(100% - 3.87rem);
    background-color: transparent;
  }
}
</style>
